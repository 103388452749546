//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'Error404',
    methods: {
        handleReturnButtonClick() {
            this.$router.replace({
                path: '/'
            })
        }
    },
    layout: 'BasicLayout'
}
